
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator/lib/decorators/Prop';
import { ExperienceAffinityClient, MatchClient, SecondChanceMatchClient, TryAgainClient } from '@/services/Services';
import PersonInfo from '@/components/personInfo.vue';
import LoveFaceShadowBar from '@/components/loveFaceShadowBar.vue';
import ReportQuestion from '@/components/reportQuestion.vue';
import ViewDescriptionModal from '../viewDescriptionModal.vue';
import * as OM from '@/Model';
import noPremiumModal from './noPremiumModal.vue';
import useTryAgainModal from '../useTryAgainModal.vue';
import store from '@/store';
import InfoModal from '@/components/modals/infoModal.vue';
import bodymovin, { AnimationItem } from 'lottie-web';
import PreviewOtherPofileModal from '../previewOtherPofileModal.vue';

@Options({
    components: {
        PersonInfo,
        LoveFaceShadowBar,
        ReportQuestion
    }
})
export default class SecondChanceModal extends Vue {

    @Prop() secondChanceId : string;
    @Prop() experienceIdentifier : string;
    @Prop() isPremium: boolean;
    @Prop() refreshCallback : any;
    @Prop() isExperience : boolean;
    @Prop({
        require: true
    }) acceptUrl : string;

    @Prop() afterPremiumAction: () => Promise<void>;

    lottieExperience: AnimationItem = null;
    lottieIsInPlayStatus: boolean = false;

    secondChance: OM.NewMatchVm = new OM.NewMatchVm();
    loaded = false;
    error = false;

    created() {
        SecondChanceMatchClient.getSecondChanceDetail(this.secondChanceId)
        .then(x => {
            this.secondChance = x;

            this.startLottieAnimation();
        }).catch( err => {
            this.error = true;
        }).finally( () => {
            this.loaded = true;
        })
    }

    openExperienceList() {
        this.$opModal.closeLast();
        this.$router.push('/experience-list/' + this.secondChance.appUserIdentifier);
    }

    startLottieAnimation() {
        if(this.secondChance && this.secondChance.hasAnyExperience) {

            if(this.lottieIsInPlayStatus){
                this.lottieExperience.destroy();
                this.lottieIsInPlayStatus = false;
            }

            setTimeout(() => {
                this.lottieExperience = bodymovin.loadAnimation({
                    container: <any>this.$refs.lottieExperience,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    path: './json/experience_loop.json',
                })

                this.lottieIsInPlayStatus = true;

                this.lottieExperience.play();
            }, 300);
        }
    }

    get affinityRateText(){
        return this.$localizationService.getLocalizedValue("app_AffinityRate{{rate}}", "Affinity Rate: {{rate}}%", "", "", { rate: this.secondChance.minimumAffinityRate });   
    }

    openDescription(){
        if(!this.secondChance.biography)
            return;

        this.$opModal.show(ViewDescriptionModal, {
            ownDescription: false,
            completeName: this.secondChance.personName,
            birthData: this.secondChance.birthDate,
            meters: this.secondChance.distanceMeters,
            sexualOrientation: this.secondChance.sexualOrientation,
            description: this.secondChance.biography
        })
    }

    refuseMatch() {
        if(this.isPremium)
            this.refuseMatchPremium();
        else
            this.modalNotPremium();
    }

    refuseMatchPremium(){
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_UnaVoltaEliminataAffinitàNonPotràEssereRecuperata", "Una volta eliminata l'affinità non potrà più essere recuperata. <br />Vuoi procedere?"),
            confirm: this.$localizationService.getLocalizedValue("app_si", "Si"),
            confirmCb: () => {
                var prom = null;

                if(!this.experienceIdentifier)
                    prom = SecondChanceMatchClient.refuseSecondChance(this.secondChance.matchIdentifier);
                else 
                    prom = ExperienceAffinityClient.refuseAffinity(this.secondChance.matchIdentifier);

                prom.then(x => {
                    this.$opModal.closeAll();
                    this.refreshCallback();
                })
            },
            deny: this.$localizationService.getLocalizedValue("app_no", "No"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    replyMatch() {
        if(!this.isPremium){
            this.modalNotPremium();
            return;
        }

        this.replyMatchPremium();
    }

    replyMatchPremium(){
        this.$opModal.closeLast();
        this.pushRoute();
    }

    pushRoute(){
        if(this.acceptUrl)
            this.$router.push(this.acceptUrl);
        else
            this.$router.push('/new-match/' + this.secondChance.matchIdentifier);
    }

    modalNotPremium(){
        this.$opModal.show(noPremiumModal, {
            text: "",
            actionText: "",
            callback: () => {
                this.$opModal.closeAll();

                store.state.afterPremiumAction = this.afterPremiumAction;

                this.$router.push("/premium");
            }
        })
    }

    viewQuestionAndAnswers(){
        if(!this.isPremium){
            this.modalNotPremium();
            return;
        }

        this.$opModal.show(PreviewOtherPofileModal, {
            match: this.secondChance,
            replyCallback: () => {
                this.$opModal.closeLast();
                this.replyMatch();
            }
        })
    }

    createChat(){
        if(!this.isPremium){
            this.modalNotPremium();
            return;
        }

        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_UnaVoltaAccettatoSiCreeràDirettamenteLaChatConQuestoProfilo.<br/>Vuoiprocedere?", "Una volta accettato si creerà direttamente la chat con questo profilo. <br />Vuoi procedere?"),
            confirm: this.$localizationService.getLocalizedValue("app_si", "Si"),
            confirmCb: () => {

                var confirmAffinityVM = null;

                var prom = null;

                if(this.experienceIdentifier){
                    confirmAffinityVM = {
                        affinityIdentifier: this.secondChance.matchIdentifier,
                        experienceIdentifier: this.experienceIdentifier
                    }

                    prom = ExperienceAffinityClient.confirmExperienceAffinity(confirmAffinityVM);

                } else {
                    confirmAffinityVM = {
                        affinityIdentifier: this.secondChance.matchIdentifier
                    }

                    prom = MatchClient.confirmAffinity(confirmAffinityVM);
                }

                prom
                .then(x => {
                    this.$opModal.closeAll()
                    this.secondChance.chatRoomIdentifier = x;
                    this.$router.replace('/chats/' + this.secondChance.chatRoomIdentifier);
                })
                .catch(err => {
                    this.$opModal.show(InfoModal, {
                        img: 'face_error.svg',
                        text: err.Message,
                        confirm: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                        confirmCb: () => {
                            this.$opModal.closeLast();
                        },
                    })
                })
            },
            deny: this.$localizationService.getLocalizedValue("app_no", "No"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

}
